var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-dialog',{attrs:{"is-saving":_vm.is_saving,"title":_vm.dialog_title,"hide-submit":_vm.page.error},on:{"submit":_vm.save},scopedSlots:_vm._u([{key:"content",fn:function(){return [(!_vm.page.error)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Name'),"rules":[
          function (v) { return !!v || _vm.$t('NameMissing'); },
          function (v) { return (v && v.length <= 45) || _vm.$t('NameMaxLength'); },
          function (v) { return (v && v.length >= 2) || _vm.$t('NameToShort'); },
          function (v) { return /^[a-zA-Z -]+$/.test(v) || _vm.$t('NameNotValid'); } ],"autofocus":"","disabled":_vm.is_saving,"outlined":"","required":""},model:{value:(_vm.child.name),callback:function ($$v) {_vm.$set(_vm.child, "name", $$v)},expression:"child.name"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-top":30,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('Birth'),"rules":[function (v) { return !!v || _vm.$t('BirthMissing'); }],"outlined":"","autocomplete":"off","disabled":!!_vm.child.id || _vm.is_saving,"readonly":"","title":_vm.child.id ? _vm.$t('CantChangeBirth') : '',"append-icon":"$vuetify.icons.fasCalendarAlt","required":""},model:{value:(_vm.child.birth),callback:function ($$v) {_vm.$set(_vm.child, "birth", $$v)},expression:"child.birth"}},'v-text-field',attrs,false),on))]}}],null,false,398429002),model:{value:(_vm.date_menu),callback:function ($$v) {_vm.date_menu=$$v},expression:"date_menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":new Date().toISOString().substr(0, 10),"min":"1980-01-01"},on:{"input":function($event){_vm.date_menu = false}},model:{value:(_vm.child.birth),callback:function ($$v) {_vm.$set(_vm.child, "birth", $$v)},expression:"child.birth"}})],1),_c('v-select',{attrs:{"outlined":"","items":_vm.sexs,"disabled":_vm.is_saving,"label":_vm.$t('Sex')},model:{value:(_vm.child.sex),callback:function ($$v) {_vm.$set(_vm.child, "sex", $$v)},expression:"child.sex"}}),(_vm.child.id)?_c('v-textarea',{attrs:{"disabled":_vm.is_saving,"label":_vm.$t('Description'),"counter":"","maxlength":"100","outlined":"","no-resize":"","rows":"3"},model:{value:(_vm.child.description),callback:function ($$v) {_vm.$set(_vm.child, "description", $$v)},expression:"child.description"}}):_vm._e(),_c('error-box',{attrs:{"error":_vm.error}})],1):_vm._e(),_c('error-box',{attrs:{"error":_vm.page.error}})]},proxy:true}]),model:{value:(_vm.is_open),callback:function ($$v) {_vm.is_open=$$v},expression:"is_open"}})}
var staticRenderFns = []

export { render, staticRenderFns }