var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.children.length < 6 && (_vm.children.length > 0 || _vm.is_admin))?_c('v-menu',{attrs:{"close-on-click":"","close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-none",class:{ 'active-menu': _vm.active_page == 'child' },attrs:{"text":"","height":"48px"}},on),[_vm._v(" "+_vm._s(_vm.$t("Children"))+" "),_c('v-icon',[_vm._v("$vuetify.icons.menuDown")])],1)]}}],null,false,3962801765)},[_c('v-list',{attrs:{"max-width":"300","min-width":"200","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_vm._l((_vm.children),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":_vm.getMenuUrl('child/' + item.url_name)}},[_c('v-list-item-avatar',[_c('child-avatar',{attrs:{"child":item,"size":40}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"global-list-header"},[_vm._v(_vm._s(_vm._f("capitalize")(item.name)))]),_c('v-list-item-subtitle',{staticClass:"global-list-desc"},[_vm._v(_vm._s(_vm.getFormatedChildAge(item.birth)))])],1),(_vm.isBirthday(item.birth))?_c('v-list-item-action',[_c('v-icon',{attrs:{"title":_vm.$t('Birthday'),"color":"purple accent-1"}},[_vm._v("$vuetify.icons.fasBirthdayCake")])],1):_vm._e()],1)}),((!_vm.children || _vm.children.length == 0) && _vm.is_admin)?_c('v-list-item',{on:{"click":_vm.openNewChild}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("AddChild")))])],1)],1):_vm._e()],2)],1)],1):_vm._e(),(
      _vm.children.length > 5 ||
      ((!_vm.children || _vm.children.length == 0) && !_vm.is_admin)
    )?_c('v-btn',{staticClass:"text-none",class:{
      'active-menu': _vm.active_page == 'child' || _vm.active_page == 'children',
    },attrs:{"text":"","to":_vm.getMenuUrl('children'),"height":"48px"}},[_vm._v(_vm._s(_vm.$t("Children")))]):_vm._e(),(_vm.is_admin)?_c('child-dialog',{ref:"childDialog"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }