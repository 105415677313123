<template>
  <div v-if="item">
    <v-card-text class="white text--primary">
      <div class="grey--text timeline-meta">{{ $t("FromDiary") }}</div>

      <div>
        <h2 class="timeline-header">
          <router-link
            :to="{
              name: 'diary_blog_post',
              params: {
                diary: diary.name,
                name: item.event_data.post.url_name,
              },
            }"
          >
            {{ getHeader(item) | capitalize }}
          </router-link>
        </h2>
        <my-clamp
          :rows="5"
          :text="getContent(item)"
          class="timeline-body"
        ></my-clamp>
      </div>

      <div class="mt-4" v-if="item.media && item.media.length > 0">
        <media-list :media="item.media"></media-list>
      </div>
    </v-card-text>
  </div>
</template>

<i18n>
    {
    "en": {
    "FromDiary": "Diary",
    "PostNotFound": "Post not found!"
    },
    "sv": {
    "FromDiary": "Dagboken",
    "PostNotFound": "Inlägget kunde inte hittas!"
    }
    }
</i18n>

<script>
import MediaList from "../TimelineItems/Helpers/MediaList.vue";
import { mapState } from "vuex";
import MyClamp from "@/components/global/MyClamp.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    "media-list": MediaList,
    "my-clamp": MyClamp
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
    }),
  },
  methods: {
    getHeader(item) {
      if (item.event_data && item.event_data.post) {
        return item.event_data.post.header;
      }
      return "";
    },
    getContent(item) {
      if (item.event_data && item.event_data.post) {
        return item.event_data.post.preamble;
      }
      return "";
    },
  },
};
</script>

<style>
</style>