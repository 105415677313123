<template>
  <div class="media-box" v-if="media && media.length > 0">
    <v-carousel
      :height="height"
      :cycle="false"
      :show-arrows="media.length > 1"
      :hide-delimiters="media.length < 2"
    >
      <v-carousel-item
        @click="current_image = index"
        v-for="(img, index) in media.slice(0, 5)"
        :key="img.id"
        class="pointer"
        :src="$imagehelpers.mediaWidth(img.src, image_size)"
      ></v-carousel-item>
    </v-carousel>
    <Tinybox :images="getImages(media)" v-model="current_image" />
  </div>
</template>

<i18n>
    {
    "en": {
    "MediaNotFound": "Media not found!"
    },
    "sv": {
    "MediaNotFound": "Mediat kunde inte hittas!"
    }
    }
</i18n>

<script>
import Tinybox from "vue-tinybox";

export default {
  name: "media-list",
  props: {
    media: {
      type: Array,
      required: false,
    },
  },
  components: {
    Tinybox,
  },
  data: () => ({
    height: 400,
    image_size: 800,
    current_image: null,
  }),
  created: function () {
    if (window.innerWidth < 700) {
      this.height = 200;
    }
  },
  methods: {
    getImages(media) {
      var data = [];

      for (var i = 0; i < media.length; i++) {
        if (i < 5) data.push(media[i].src.original);
      }
      return data;
    },
  },
};
</script>

<style>
.media-box {
  margin-left: -15px !important;
  margin-right: -15px !important;
  margin-bottom: -15px;
}
</style>