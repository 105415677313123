<template>
  <div>
    <v-menu
      close-on-click
      close-on-content-click
      offset-y
      v-if="children.length < 6 && (children.length > 0 || is_admin)"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-bind:class="{ 'active-menu': active_page == 'child' }"
          v-on="on"
          height="48px"
          class="text-none"
        >
          {{ $t("Children") }}
          <v-icon>$vuetify.icons.menuDown</v-icon>
        </v-btn>
      </template>
      <v-list max-width="300" min-width="200" dense>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in children"
            :to="getMenuUrl('child/' + item.url_name)"
            :key="i"
          >
            <v-list-item-avatar>
              <child-avatar :child="item" :size="40"></child-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="global-list-header">{{
                item.name | capitalize
              }}</v-list-item-title>
              <v-list-item-subtitle class="global-list-desc">{{
                getFormatedChildAge(item.birth)
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action v-if="isBirthday(item.birth)">
              <v-icon :title="$t('Birthday')" color="purple accent-1"
                >$vuetify.icons.fasBirthdayCake</v-icon
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            v-if="(!children || children.length == 0) && is_admin"
            @click="openNewChild"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t("AddChild") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-btn
      v-if="
        children.length > 5 ||
        ((!children || children.length == 0) && !is_admin)
      "
      v-bind:class="{
        'active-menu': active_page == 'child' || active_page == 'children',
      }"
      text
      :to="getMenuUrl('children')"
      height="48px"
      class="text-none"
      >{{ $t("Children") }}</v-btn
    >

    <child-dialog ref="childDialog" v-if="is_admin"></child-dialog>
  </div>
</template>

<i18n>
    {
    "en": {
    "Children": "Children",
    "AddChild": "Add child",
    "Birthday": "Birthday"
    },
    "sv": {
    "Children": "Barn",
    "AddChild": "Lägg till",
    "Birthday": "Födelsedag"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import ChildDialog from "@/components/diary/dialogs/ChildFormDialog.vue";
import ChildAvatar from "@/components/avatars/ChildAvatar.vue";
import * as moment from "moment";
import { dateHelpers } from "@/_helpers";

export default {
  name: "DiaryMenuChildrenItem",
  computed: {
    ...mapState({
      children: (state) => state.diary.children.list,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "child-dialog": ChildDialog,
    "child-avatar": ChildAvatar,
  },
  data: () => ({
    active_page: "",
  }),
  methods: {
    ...mapActions({}),
    getFormatedChildAge: dateHelpers.getFormatedChildAge,
    setMenu() {
      this.active_page = "";

      if (window.location.toString().indexOf("/child/") > -1) {
        this.active_page = "child";
      }

      if (window.location.toString().indexOf("/children") > -1) {
        this.active_page = "children";
      }

      if (window.location.toString().indexOf("/admin/") > -1) {
        this.active_page = "admin";
      }
    },
    openNewChild() {
      this.$refs.childDialog.open(this.diary.name);
    },
    isBirthday(date) {
      return moment(date).isSame(moment(), "day");
    },
    getMenuUrl(name) {
      if (!name) {
        return "/" + this.diary.name;
      }
      return "/" + this.diary.name + "/" + name;
    },
  },
  watch: {
    $route() {
      this.setMenu();
    },
  },
};
</script>

<style scoped>
</style>