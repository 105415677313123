<template>
  <div v-if="item && item.child">
    <v-card-text class="white text--primary">
      <div>
        <div class="grey--text timeline-meta">{{ $t("Child") }}</div>

        <h2 class="timeline-header">
          <router-link
            :to="{
              name: 'child_home',
              params: { diary: diary.name, name: item.child.url_name },
            }"
          >
            {{ item.child.name | capitalize }}</router-link
          >
        </h2>

        <div class="timeline-body">
          {{ $tc("BirthThisDay", calculateAge(), { years: calculateAge() }) }}
        </div>
      </div>

      <div class="mt-4" v-if="item.media && item.media.length > 0">
        <media-list :media="item.media"></media-list>
      </div>
    </v-card-text>
  </div>
</template>

<i18n>
    {
    "en": {
    "Child": "Birthday",
    "BirthThisDay": "Turned {years} year old. | Turned {years} years old."
    },
    "sv": {
    "Child": "Födelsedag",
    "BirthThisDay": "Fyllde denna dag {years} år. | Fyllde denna dag {years} år."
    }
    }
</i18n>

<script>
import MediaList from "../TimelineItems/Helpers/MediaList.vue";
import { mapState } from "vuex";
import { dateHelpers } from "@/_helpers";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    "media-list": MediaList,
  },
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
    }),
  },
  methods: {
    calculateAge() {
      if (this.item.child) {
        return dateHelpers.getDateDiffFromDateObject(
          this.item.child.birth,
          this.item.event_date
        ).years;
      }
      return null;
    },
  },
};
</script>