<template>
  <div>
    <v-card-text class="white text--primary">
      <h2 class="timeline-header">{{ getHeader() | capitalize }}</h2>

      <div v-if="getContent()" class="body-1">
        <my-clamp :rows="5" :text="getContent()" class="timeline-body"></my-clamp>
      </div>

      <div class="mt-4" v-if="item.media && item.media.length > 0">
        <media-list :media="item.media"></media-list>
      </div>
    </v-card-text>
  </div>
</template>

<i18n>
  {
  "en": {
  },
  "sv": {
  }
  }
</i18n>

<script>
  import MediaList from '../TimelineItems/Helpers/MediaList.vue'
import MyClamp from "@/components/global/MyClamp.vue";
  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    components: {
      'media-list': MediaList,
      "my-clamp": MyClamp
    },
    methods: {
      getHeader() {
        if (this.item.event_data && this.item.event_data.header) {
          return this.item.event_data.header;
        }
        return "";
      },
      getContent() {
        if (this.item.event_data && this.item.event_data.content) {
          return this.item.event_data.content;
        }
        return "";
      }
    }
  }
</script>

<style>
</style>
