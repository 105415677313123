<template>
  <v-card :loading="is_loading" :disabled="is_saving" elevation="1">
    <v-card-title class="pb-2">{{ $t("Memories") }}</v-card-title>

    <v-card-text
      v-if="!memories || memories.length == 0"
      class="text-center pt-2"
      >{{ $t("NoMemories") }}</v-card-text
    >

    <v-list class="mt-0 pt-0" nav v-if="memories && memories.length > 0">
      <v-list-item-group color="primary">
        <v-list-item
          :to="getLink(item)"
          two-line
          :key="item.id"
          v-for="item in memories.slice(0, 3)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ getTitle(item) }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.date }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <div class="text-center pb-3 pt-2" v-if="memories.length > 3">
        <router-link class="link" :to="getMemoriesLink('')">{{
          $t("ShowAll")
        }}</router-link>
      </div>
    </v-list>
  </v-card>
</template>

<i18n>
    {
    "en": {
    "Memories": "Memories",
    "NoMemories": "There are no saved memories to show!",
    "ShowAll": "Show all"
    },
    "sv": {
    "Memories": "Minnen",
    "NoMemories": "Det finns inga sparade minnen att visa!",
    "ShowAll": "Visa alla"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { childMemories } from "../../../_helpers";

export default {
  name: "ChildMemoriesWidget",
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      child: (state) => state.diary.childprofile.child,

      memories: (state) => state.diary.childprofile.memories.items,
      is_loading: (state) => state.diary.childprofile.memories.is_loading,
      is_saving: (state) => state.diary.childprofile.memories.is_saving,
    }),
  },
  methods: {
    getTitle(item) {
      if (item.title) return item.title;

      var temp = childMemories.getList().find((p) => p.key == item.url_name);

      if (temp.title) return temp.title;

      return "";
    },
    getLink(item) {
      return this.getMemoriesLink("/" + item.url_name);
    },
    getMemoriesLink(url) {
      return (
        "/" +
        this.diary.name +
        "/child/" +
        this.child.url_name +
        "/memories" +
        url
      );
    },
  },
};
</script>

<style scoped>
.link {
  text-decoration: none;
  font-size: 15px;
}
.link:hover {
  text-decoration: underline;
}
</style>