<template>
  <div v-if="item">
    <v-card-text class="white text--primary">
      <div class="grey--text timeline-meta">{{ $t("NewImages") }}</div>

      <h2 class="timeline-header">
        <router-link
          :to="{
            name: 'diary_album_profile',
            params: { diary: diary.name, name: item.event_data.album.url_name },
          }"
          >{{ getHeader(item) | capitalize }}</router-link
        >
      </h2>

      <div class="mt-4" v-if="item.media && item.media.length > 0">
        <media-list :media="item.media"></media-list>
      </div>
    </v-card-text>
  </div>
</template>

<i18n>
    {
    "en": {
    "NewImages": "Album"
    },
    "sv": {
    "NewImages": "Album"
    }
    }
</i18n>

<script>
import MediaList from "../TimelineItems/Helpers/MediaList.vue";
import { mapState } from "vuex";

export default {
  name: "media-album-feed",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
    }),
  },
  components: {
    "media-list": MediaList,
  },
  methods: {
    getHeader(item) {
      if (item.event_data && item.event_data.album) {
        return item.event_data.album.header;
      }
      return "";
    },
  },
};
</script>