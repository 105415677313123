var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',[_c('v-card-text',{staticClass:"white text--primary"},[_c('div',[_c('div',{staticClass:"grey--text timeline-meta"},[_vm._v(_vm._s(_vm.$t("Memory")))]),_c('h2',{staticClass:"timeline-header"},[_c('router-link',{attrs:{"to":{
            name: 'diary_child_memories_read',
            params: {
              diary: _vm.diary.name,
              name: _vm.item.child.url_name,
              type: _vm.item.event_data.memory.url_name,
            },
          }}},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.item.child.name)))])],1),_c('div',{staticClass:"timeline-subheader"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.getHeader(_vm.item))))]),_c('my-clamp',{staticClass:"timeline-body",attrs:{"rows":5,"text":_vm.getContent(_vm.item)}})],1),(_vm.item.media && _vm.item.media.length > 0)?_c('div',{staticClass:"mt-4"},[_c('media-list',{attrs:{"media":_vm.item.media}})],1):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }