<template>
  <div v-if="post && post.cover && post.cover.length > 0">
    <template v-if="post.cover.length > 1 && height > 399">
      <v-carousel v-model="slider" :height="height">
        <v-carousel-item v-for="img in post.cover" :key="img.id" :src="img.image.width_800">
        </v-carousel-item>
      </v-carousel>
    </template>
    <template v-else>
      <v-img
        :height="height"
        :alt="post.cover[0].title"
        :title="post.cover[0].title"
        :src="post.cover[0].image.width_800"
      ></v-img>
    </template>
  </div>
</template>

<script>
export default {
  name: "blogpost_cover",
  props: {
    post: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default: 400
    }
  },
  data: () => ({
    slider: 0
  }),
};
</script>