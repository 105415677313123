<template>
  <v-avatar class="grey lighten-2" :size="size" :title="child.name">
    <template v-if="child.avatar && !is_error">
      <v-img
        :src="$imagehelpers.childAvatar(child.avatar, size)"
        :title="child.name"
        @error="is_error = true"
        :alt="child.name"
        @click="onClick"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </template>
    <template v-if="!child.avatar || is_error">
      <v-icon :style="getEmptyStyle()">
        <template v-if="getDateDiffObject(child.birth).years == 0"
          >$vuetify.icons.fasBaby</template
        >
        <template v-else>$vuetify.icons.fasChild</template>
      </v-icon>
    </template>
  </v-avatar>
</template>

<script>
import {dateHelpers } from "@/_helpers";

export default {
  props: {
    child: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      is_error: false,
    };
  },
  methods: {
    getDateDiffObject: dateHelpers.getDateDiffObject,

    onClick() {
      this.$emit("clicked", this.child.avatar);
    },
    getEmptyStyle() {
      var fontSize = this.size * 0.6;

      return "font-size: " + fontSize + "px !important";
    },
  },
};
</script>