<template>
  <v-row class="blog-post-cards mobile-margin-fix mt-0" align="stretch">
    <v-col
      v-for="item in news"
      :key="item.id"
      cols="12"
      xs="12"
      md="4"
      class="pt-0 mb-3"
    >
      <v-hover v-slot:default="{ hover }">
        <v-card :elevation="hover ? 2 : 1" class="mb-3 fill-height" flat>
          <v-card class="fill-height flexcard" :to="getUrl(item)">
            <post-cover
              :post="item"
              :height="150"
              v-if="item.cover && item.cover.length > 0 && coverType == 1"
            ></post-cover>
            <v-card-title class="news-title">
              <h2 class="headline">
                {{ item.header | capitalize }}
              </h2>
            </v-card-title>

            <v-card-subtitle class="caption">
              {{ $t("Published") }} {{ getDate(item.show_date) }}
              <privacy-icon :privacy="item.privacy"></privacy-icon>
            </v-card-subtitle>
            <post-cover
              :post="item"
              :height="150"
              v-if="item.cover && item.cover.length > 0 && coverType == 2"
            ></post-cover>
            <v-card-text class="text--primary grow">
              <div class="news-preamble">{{ item.preamble }}</div>
            </v-card-text>
            <v-divider class="mr-4 ml-4"></v-divider>
            <v-card-actions class="pr-4 pl-4 pt-3 pb-3">
              <v-icon :title="$t('Comments')" class="comments-icon"
                >$vuetify.icons.commentOutline</v-icon
              ><span class="unit-comments">{{ item.unit_comments }}</span>
              <v-spacer></v-spacer>
              <my-share
                :title="item.header"
                v-if="
                  (item.settings && !item.settings.hide_share) || !item.settings
                "
                :url="getUrl(item)"
              ></my-share>
            </v-card-actions>
          </v-card>
          <post-menu
            v-if="user"
            v-on:deleted="onDeleted"
            v-on:updated="onUpdated"
            :post="item"
          ></post-menu>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<i18n>
    {
    "en": {
    "Comments": "Comments",
    "Published": "Published"
    },
    "sv": {
    "Comments": "Kommentarer",
    "Published": "Publicerad"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import BlogPostMenu from "@/components/diary/helpers/BlogPostMenu.vue";
import PrivacyIcon from "@/components/global/Privacy.vue";
import BlogPostCover from "../BlogPostCover.vue";
import Share from "@/components/global/Share.vue";
import { dateHelpers } from "@/_helpers";

export default {
  name: "diary_news_cards",
  props: ["news"],
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      settings: (state) => state.diary.settings,
      is_admin: (state) => state.diary.is_admin,
    }),
    coverType: function () {
      if (
        !this.settings ||
        !this.settings.BlogPageSettings ||
        this.settings.BlogPageSettings.cover_display == undefined ||
        this.settings.BlogPageSettings.cover_display == null
      )
        return 1;
      return this.settings.BlogPageSettings.cover_display;
    },
  },

  components: {
    "post-menu": BlogPostMenu,
    "privacy-icon": PrivacyIcon,
    "post-cover": BlogPostCover,
    "my-share": Share,
  },
  created: function () {},
  methods: {
    onDeleted(item) {
      this.$emit("deleted", item);
    },
    onUpdated(item) {
      this.$emit("updated", item);
    },
    getUrl(item) {
      return "/" + this.diary.name + "/diary/" + item.url_name;
    },
    getDate(date) {
      var dt = dateHelpers.getFormatedDateFromDatetime(date);
      return dateHelpers.getFormatedTimelineDate(dt);
    },
  },
};
</script>

<style scoped>
.blog-post-cards .news-title h2 {
  padding-right: 40px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 20px !important;
  line-height: 27px !important;
}
.news-preamble {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-post-cards .comments-icon {
  margin-right: 7px;
}
.blog-post-cards .unit-comments {
  color: gray;
  font-size: 14px;
}

.blog-post-cards .share-button {
  bottom: 5px !important;
}
</style>