<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="dialog_title"
    v-on:submit="save"
    :hide-submit="page.error"
  >
    <template v-slot:content>
      <v-form ref="form" v-model="form" lazy-validation v-if="!page.error">
        <v-text-field
          :label="$t('Name')"
          :rules="[
            (v) => !!v || $t('NameMissing'),
            (v) => (v && v.length <= 45) || $t('NameMaxLength'),
            (v) => (v && v.length >= 2) || $t('NameToShort'),
            (v) => /^[a-zA-Z -]+$/.test(v) || $t('NameNotValid'),
          ]"
          autofocus
          v-model="child.name"
          :disabled="is_saving"
          outlined
          required
        ></v-text-field>

        <v-menu
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-top="30"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :label="$t('Birth')"
              :rules="[(v) => !!v || $t('BirthMissing')]"
              v-model="child.birth"
              outlined
              autocomplete="off"
              :disabled="!!child.id || is_saving"
              readonly
              :title="child.id ? $t('CantChangeBirth') : ''"
              append-icon="$vuetify.icons.fasCalendarAlt"
              v-bind="attrs"
              v-on="on"
              required
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="child.birth"
            :max="new Date().toISOString().substr(0, 10)"
            min="1980-01-01"
            @input="date_menu = false"
          ></v-date-picker>
        </v-menu>

        <v-select
          outlined
          :items="sexs"
          v-model="child.sex"
          :disabled="is_saving"
          :label="$t('Sex')"
        ></v-select>

        <v-textarea
          :disabled="is_saving"
          :label="$t('Description')"
          counter
          maxlength="100"
          outlined
          no-resize
          rows="3"
          v-if="child.id"
          v-model="child.description"
        ></v-textarea>
        <error-box :error="error"></error-box>
      </v-form>
      <error-box :error="page.error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "EditChild": "Edit child",
    "AddChild": "Add child",
    "Name": "Name",
    "Birth": "Birthdate",
    "Description": "Presentation",
    "NameMissing": "Name is missing!",
    "NameMaxLength": "Name must be less than 45 characters",
    "NameNotValid": "Name not valid. Use a-zA-Z0-9 (space) or -",
    "NameToShort": "Name to short. At least 2 characters",
    "BirthMissing": "Birth is missing!",
    "Sex": "Sex",
    "Boy": "Boy",
    "Girl": "Girl",
    "Other": "Other",
    "NotSet": "Not set",
    "SuccessUpdate": "Information updated!",
    "CantChangeBirth": "You can't change the birthdate!",
    "SuccessCreate": "Child created!"
    },
    "sv": {
    "EditChild": "Redigera barn",
    "AddChild": "Lägg till barn",
    "Name": "Namn",
    "Birth": "Födelsedatum",
    "Description": "Presentation",
    "NameMissing": "Namn saknas!",
    "NameMaxLength": "Namnet får inte vara längre än 45 tecken",
    "NameNotValid": "Namnet är inte korrekt. Använd a-zA-Z0-9 (mellanslag) eller -",
    "NameToShort": "Namnet för kort. Minst 2 tecken",
    "BirthMissing": "Födelsedatum saknas!",
    "Sex": "Kön",
    "Boy": "Pojke",
    "Girl": "Flicka",
    "Other": "Annat",
    "NotSet": "Ej valt",
    "SuccessUpdate": "Informationen uppdaterades!",
    "CantChangeBirth": "Du kan inte ändra födelsedatumet!",
    "SuccessCreate": "Barnet skapades!"
    }
    }
</i18n>

<script>
import { mapActions } from "vuex";
import { childrenService } from "../../../_services";
import FormDialog from "../../helpers/FormDialog.vue";

export default {
  components: { FormDialog },
  name: "diary_child_form_dialog",
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },

    form: false,
    error: null,
    is_saving: false,
    date_menu: false,
    is_open: false,
    dialog_title: "",

    child: {
      name: "",
      birth: "",
      description: "",
      avatar: "",
      sex: 0,
      id: null,
    },

    diary_name: null, //only when create new

    sexs: [],
  }),
  created: function () {
    this.sexs = [
      { value: this.$enums.SEXTYPES.NOT_SET, text: this.$t("NotSet") },
      { value: this.$enums.SEXTYPES.BOY, text: this.$t("Boy") },
      { value: this.$enums.SEXTYPES.GIRL, text: this.$t("Girl") },
      { value: this.$enums.SEXTYPES.OTHER, text: this.$t("Other") },
    ];
    this.dialog_title = this.$t("AddChild");
  },
  methods: {
    ...mapActions({
      update: "diary/children/update",
      create: "diary/children/createInDiary",
    }),
    open(diary_name) {
      var self = this;
      self.is_open = true;
      self.child.name = "";
      self.child.birth = "";
      self.child.description = "";
      self.child.sex = 0;
      self.error = null;
      self.diary_name = diary_name;
      self.is_saving = false;
    },
    edit(id) {
      this.dialog_title = this.$t("EditChild");
      var self = this;
      self.page.is_loading = true;
      self.is_open = true;
      self.child.id = id;

      childrenService
        .getChild(id)
        .then(function (response) {
          self.child = { ...response.data };
          self.page.is_loading = false;
        })
        .catch(function (error) {
          self.page.error = error;
          self.page.is_loading = false;
        });
    },
    editChild(child) {
      this.dialog_title = this.$t("EditChild");
      this.child = { ...child };
      this.is_open = true;
    },

    save() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        if (self.child.id) {
          self.updateChild();
        } else {
          self.createChild();
        }
      }
    },

    updateChild() {
      var self = this;
      self
        .update(self.child)
        .then(function () {
          self.$successNoty(self.$t("SuccessUpdate"));

          self.is_saving = false;
          self.$emit("updated", self.child);
        })
        .catch(function (error) {
          self.error = error;
          self.is_saving = false;
        });
    },
    createChild() {
      var self = this;
      self
        .create({ diary_name: self.diary_name, child: self.child })
        .then(function (response) {
          self.$successNoty(self.$t("SuccessCreate"));

          self.is_saving = false;
          self.is_open = false;
          self.$emit("created", response.data);
          self.$refs.form.resetValidation();
          self.$router.push(
            "/" +
              self.diary_name +
              "/child/" +
              response.data.url_name +
              "?is_new=true"
          );
        })
        .catch(function (error) {
          self.error = error;
          self.is_saving = false;
        });
    },

    close() {
      this.is_open = false;
    },
  },
  watch: {
    date_menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
};
</script>