<template>
  <div>
    <v-card
      v-for="item in news"
      :key="item.id"
      class="mb-6"
      :flat="flat"
      elevation="1"
    >
      <v-card-text class="pa-0 post-covers">
        <post-cover
          :post="item"
          v-if="item.cover && item.cover.length > 0 && coverType == 1"
        ></post-cover>
      </v-card-text>

      <v-card-title class="news-title">
        <h2 class="headline">
          <router-link :to="getUrl(item)">{{
            item.header | capitalize
          }}</router-link>
        </h2>
        <post-menu
          v-if="user"
          v-on:deleted="onDeleted"
          v-on:updated="onUpdated"
          class="diary-post-menu"
          :post="item"
        ></post-menu>
      </v-card-title>

      <v-card-subtitle class="caption">
        {{ $t("Published") }} {{ getDate(item.show_date) }}
        <privacy-icon :privacy="item.privacy"></privacy-icon>
      </v-card-subtitle>
      <v-card-text class="pa-0 post-covers">
        <post-cover
          :post="item"
          v-if="item.cover && item.cover.length > 0 && coverType == 2"
        ></post-cover>
      </v-card-text>
      <v-card-text
        v-html="item.text"
        class="text--primary image-width-fix"
      ></v-card-text>

      <template
        v-if="
          item.allow_comments || (item.settings && !item.settings.hide_share)
        "
      >
        <v-divider></v-divider>
        <v-card-actions class="pr-4 pt-3 pb-3">
          <v-btn
            v-if="item.allow_comments"
            color="primary"
            text
            class="text-none"
            :to="getUrl(item) + '#comments'"
            >{{ $t("Comments") }} [{{ item.unit_comments }}]</v-btn
          >
          <v-spacer></v-spacer>
          <my-share
            v-if="
              (item.settings && !item.settings.hide_share) || !item.settings
            "
            :title="item.header"
            :url="getUrl(item)"
          ></my-share>
        </v-card-actions>
      </template>
      <v-divider v-if="flat"></v-divider>
    </v-card>
  </div>
</template>

<i18n>
    {
    "en": {
    "Comments": "Comments",
    "Published": "Published"
    },
    "sv": {
    "Comments": "Kommentarer",
    "Published": "Publicerad"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import PrivacyIcon from "../../../../components/global/Privacy.vue";
import BlogPostCover from "../BlogPostCover.vue";
import BlogPostMenu from "../../../../components/diary/helpers/BlogPostMenu.vue";
import Share from "@/components/global/Share.vue";
import { dateHelpers } from "@/_helpers";

export default {
  name: "diary_news_list",
  props: {
    news: {
      type: Array,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      settings: (state) => state.diary.settings,
      is_admin: (state) => state.diary.is_admin,
    }),
    coverType: function () {
      if (
        !this.settings ||
        !this.settings.BlogPageSettings ||
        this.settings.BlogPageSettings.cover_display == undefined ||
        this.settings.BlogPageSettings.cover_display == null
      )
        return 1;
      return this.settings.BlogPageSettings.cover_display;
    },
  },

  components: {
    "post-menu": BlogPostMenu,
    "privacy-icon": PrivacyIcon,
    "post-cover": BlogPostCover,
    "my-share": Share,
  },

  created: function () {},
  methods: {
    onDeleted(item) {
      this.$emit("deleted", item);
    },
    onUpdated(item) {
      this.$emit("updated", item);
    },
    getUrl(item) {
      return "/" + this.diary.name + "/diary/" + item.url_name;
    },
    getDate(date) {
      var dt = dateHelpers.getFormatedDateFromDatetime(date);
      return dateHelpers.getFormatedTimelineDate(dt);
    },
  },
};
</script>

<style scoped>
.news-title a {
  text-decoration: none;
  color: black !important;
}

.news-title a:hover {
  text-decoration: underline;
}

.news-title h2 {
  padding-right: 40px !important;
}
</style>