<template>
  <div>
    <template v-if="child.description || is_admin">
      <v-card-text>
        <template v-if="!is_edit">
          <div
            v-if="child.description"
            class="text-fix text-center text--primary pl-2 pr-2 pt-0"
          >
            {{ child.description | capitalize }}
            <div v-if="is_admin" class="mt-2">
              <v-btn
                color="primary"
                text
                small
                @click="editDescription"
                class="text-none"
                >{{ $t("EditBio") }}</v-btn
              >
            </div>
          </div>
          <div v-if="!child.description && is_admin" class="text-center">
            {{ $t("DescriptionMissing") }} {{ child.name | capitalize }}
            <div>
              <v-btn
                color="primary"
                text
                small
                @click="editDescription"
                class="text-none"
                >{{ $t("AddBio") }}</v-btn
              >
            </div>
          </div>
        </template>
        <template v-if="is_edit && is_admin">
          <v-textarea
            :disabled="is_saving"
            :placeholder="$t('BioPlaceholder') + ' ' + child.name"
            counter
            maxlength="100"
            no-resize
            v-model="text"
            outlined
            auto-grow
          ></v-textarea>
          <div class="text-right">
            <v-btn
              depressed
              small
              @click="cancelDescription"
              class="mr-1 text-none"
              :disabled="is_saving"
              >{{ $t("Button.Cancel") }}</v-btn
            >
            <v-btn
              depressed
              small
              color="primary"
              @click="saveDescription"
              :loading="is_saving"
              class="text-none"
              >{{ $t("Button.Save") }}</v-btn
            >
          </div>
          <div v-if="error" class="error-text">{{ description.error }}</div>
        </template>
      </v-card-text>
    </template>

    <template v-if="!child.description && !is_admin">
      <v-card-text class="text-fix text-center pl-2 pr-2">{{
        $t("EmptyBio")
      }}</v-card-text>
    </template>
  </div>
</template>

<i18n>
    {
    "en": {
    "DescriptionMissing": "Add a short bio to tell people more about",
    "AddBio": "Add bio",
    "EditBio": "Edit bio",
    "BioPlaceholder": "Tell us more about",
    "Intro": "Intro",
    "SuccessUpdate": "Intro updated!",
    "EmptyBio": "There is no bio written yet!"
    },
    "sv": {
    "DescriptionMissing": "Lägg till en kort bakgrund för att berätta mer om",
    "AddBio": "Lägg till biografi",
    "EditBio": "Redigera biografi",
    "BioPlaceholder": "Berätta mer om",
    "Intro": "Introduktion",
    "SuccessUpdate": "Introduktion uppdaterades!",
    "EmptyBio": "Ingen introduktion har skrivits ännu!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ChildAbout",
  data: () => ({
    text: "",
    is_edit: false,
    is_saving: false,
    error: null,
  }),
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      child: (state) => state.diary.childprofile.child,
    }),
  },
  methods: {
    ...mapActions({
      updateDescription: "diary/childprofile/updateDescription",
    }),
    editDescription() {
      this.is_edit = true;
      this.text = this.child.description;
    },
    cancelDescription() {
      this.is_edit = false;
    },
    saveDescription() {
      var self = this;
      self.error = null;
      self.is_saving = true;

      self
        .updateDescription(self.text)
        .then(function () {
          self.is_saving = false;
          self.is_edit = false;
          self.$successNoty(self.$t("SuccessUpdate"));
        })
        .catch(function (error) {
          self.is_saving = false;
          self.error = error;
        });
    },
  },
};
</script>